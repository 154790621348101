var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    [
      _c(
        "v-expansion-panel-header",
        {
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c("v-icon", { staticClass: "icon", attrs: { left: "" } }, [
                    _vm._v(" mdi-chevron-down "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "font-weight-bold header" }, [
            _vm._v("POS information"),
          ]),
        ]
      ),
      _c(
        "v-expansion-panel-content",
        { staticClass: "pl-8" },
        [
          _c(
            "field-row",
            {
              attrs: {
                title: "Integrated",
                tooltip:
                  "Integrated – Your payment terminal speaks to your point of sale\nNon-integrated – Your payment terminal does not speak to your point of sale",
              },
            },
            [
              _c(
                "v-radio-group",
                {
                  attrs: { row: "", dense: "" },
                  model: {
                    value: _vm.value.isIntegrated,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "isIntegrated", $$v)
                    },
                    expression: "value.isIntegrated",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: { value: "integrated", label: "Integrated" },
                  }),
                  _c("v-radio", {
                    attrs: { value: "nonIntegrated", label: "Non-integrated" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.value.isIntegrated === "nonIntegrated"
            ? _c("div", { staticClass: "font-weight-bold pt-4 text-center" }, [
                _vm._v(
                  " Thank you! Please reach out to support@getgrab for assistance with creating a Grab tender. "
                ),
              ])
            : _vm.value.isIntegrated === "integrated"
            ? [
                _c(
                  "field-row",
                  { attrs: { title: "POS" } },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          [
                            _c("v-select", {
                              attrs: {
                                label: "POS",
                                items: _vm.posItems,
                                attach: "",
                                dense: "",
                                required: "",
                                rules: [(v) => !!v || "POS type is required!"],
                              },
                              model: {
                                value: _vm.selectedPos,
                                callback: function ($$v) {
                                  _vm.selectedPos = $$v
                                },
                                expression: "selectedPos",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          [
                            _vm.selectedPos === "omnivore"
                              ? _c("v-select", {
                                  attrs: {
                                    label: "Omnivore type",
                                    items: _vm.omnivoreItems,
                                    dense: "",
                                    attach: "",
                                    required: "",
                                    rules: [
                                      (v) =>
                                        !!v || "Omnivore type is required!",
                                    ],
                                  },
                                  model: {
                                    value: _vm.selectedOmnivoreType,
                                    callback: function ($$v) {
                                      _vm.selectedOmnivoreType = $$v
                                    },
                                    expression: "selectedOmnivoreType",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.selectedPos === "hmshost"
                  ? _c(
                      "field-row",
                      { attrs: { title: "HMSHost Middleware" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: "SubscriptionKey",
                            clearable: "",
                            dense: "",
                            required: "",
                            rules: [
                              (v) => !!v || "SubscriptionKey is required!",
                            ],
                          },
                          model: {
                            value: _vm.value.hmshost.subscriptionKey,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.value.hmshost,
                                "subscriptionKey",
                                $$v
                              )
                            },
                            expression: "value.hmshost.subscriptionKey",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "ClientID",
                            clearable: "",
                            dense: "",
                            required: "",
                            rules: [(v) => !!v || "ClientID is required!"],
                          },
                          model: {
                            value: _vm.value.hmshost.clientID,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.hmshost, "clientID", $$v)
                            },
                            expression: "value.hmshost.clientID",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "UserName",
                            clearable: "",
                            dense: "",
                            required: "",
                            rules: [(v) => !!v || "UserName is required!"],
                          },
                          model: {
                            value: _vm.value.hmshost.username,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.hmshost, "username", $$v)
                            },
                            expression: "value.hmshost.username",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "PassKey",
                            clearable: "",
                            dense: "",
                            required: "",
                            rules: [
                              (v) => !!v || "PassKey location is required!",
                            ],
                          },
                          model: {
                            value: _vm.value.hmshost.passKey,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.hmshost, "passKey", $$v)
                            },
                            expression: "value.hmshost.passKey",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "RevenueCenterID",
                            clearable: "",
                            dense: "",
                            required: "",
                            rules: [
                              (v) =>
                                !!v || "RevenueCenterID location is required!",
                            ],
                          },
                          model: {
                            value: _vm.value.hmshost.revenueCenterID,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.value.hmshost,
                                "revenueCenterID",
                                $$v
                              )
                            },
                            expression: "value.hmshost.revenueCenterID",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "PropertyID",
                            clearable: "",
                            dense: "",
                            required: "",
                            rules: [
                              (v) => !!v || "PropertyID location is required!",
                            ],
                          },
                          model: {
                            value: _vm.value.hmshost.propertyID,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.hmshost, "propertyID", $$v)
                            },
                            expression: "value.hmshost.propertyID",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "BaseURL",
                            clearable: "",
                            dense: "",
                            required: "",
                            rules: [
                              (v) => !!v || "BaseURL location is required!",
                            ],
                          },
                          model: {
                            value: _vm.value.hmshost.baseURL,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.hmshost, "baseURL", $$v)
                            },
                            expression: "value.hmshost.baseURL",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "OfflineURL",
                            clearable: "",
                            dense: "",
                            required: "",
                            rules: [
                              (v) => !!v || "OfflineURL location is required!",
                            ],
                          },
                          model: {
                            value: _vm.value.hmshost.offlineURL,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.hmshost, "offlineURL", $$v)
                            },
                            expression: "value.hmshost.offlineURL",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "TenderType",
                            clearable: "",
                            dense: "",
                            required: "",
                            rules: [
                              (v) => !!v || "TenderType location is required!",
                            ],
                          },
                          model: {
                            value: _vm.value.hmshost.tenderType,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.hmshost, "tenderType", $$v)
                            },
                            expression: "value.hmshost.tenderType",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "OrderTypeIDRef",
                            clearable: "",
                            dense: "",
                            required: "",
                            rules: [
                              (v) =>
                                !!v || "OrderTypeIDRef location is required!",
                            ],
                          },
                          model: {
                            value: _vm.value.hmshost.orderTypeIDRef,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.hmshost, "orderTypeIDRef", $$v)
                            },
                            expression: "value.hmshost.orderTypeIDRef",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedPos === "agilysis"
                  ? _c(
                      "field-row",
                      { attrs: { title: "Agilysis" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: "Authentication code",
                            dense: "",
                            clearable: "",
                            required: "",
                            rules: [
                              (v) => !!v || "Authentication code is required!",
                            ],
                          },
                          model: {
                            value: _vm.value.agilysis.authCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.agilysis, "authCode", $$v)
                            },
                            expression: "value.agilysis.authCode",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "Client ID",
                            dense: "",
                            clearable: "",
                            required: "",
                            rules: [(v) => !!v || "Client ID is required!"],
                          },
                          model: {
                            value: _vm.value.agilysis.clientID,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.agilysis, "clientID", $$v)
                            },
                            expression: "value.agilysis.clientID",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "Soap URL",
                            dense: "",
                            clearable: "",
                            required: "",
                            rules: [(v) => !!v || "Soap URL is required!"],
                          },
                          model: {
                            value: _vm.value.agilysis.soapURL,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.agilysis, "soapURL", $$v)
                            },
                            expression: "value.agilysis.soapURL",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "Tender ID",
                            dense: "",
                            clearable: "",
                            required: "",
                            rules: [(v) => !!v || "Tender ID is required!"],
                          },
                          model: {
                            value: _vm.value.agilysis.tenderID,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.agilysis, "tenderID", $$v)
                            },
                            expression: "value.agilysis.tenderID",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "Check Type ID",
                            dense: "",
                            clearable: "",
                            required: "",
                            rules: [(v) => !!v || "Check Type ID is required!"],
                          },
                          model: {
                            value: _vm.value.agilysis.checkTypeID,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.agilysis, "checkTypeID", $$v)
                            },
                            expression: "value.agilysis.checkTypeID",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "Employee ID",
                            dense: "",
                            clearable: "",
                            required: "",
                            rules: [(v) => !!v || "Employee ID is required!"],
                          },
                          model: {
                            value: _vm.value.agilysis.employeeID,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.agilysis, "employeeID", $$v)
                            },
                            expression: "value.agilysis.employeeID",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "Price Level",
                            dense: "",
                            clearable: "",
                            required: "",
                            rules: [(v) => !!v || "Price Level is required!"],
                          },
                          model: {
                            value: _vm.value.agilysis.priceLevel,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.agilysis, "priceLevel", $$v)
                            },
                            expression: "value.agilysis.priceLevel",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "Profit Centre ID",
                            dense: "",
                            clearable: "",
                            required: "",
                            rules: [
                              (v) => !!v || "Profit Centre ID is required!",
                            ],
                          },
                          model: {
                            value: _vm.value.agilysis.profitCentreID,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.value.agilysis,
                                "profitCentreID",
                                $$v
                              )
                            },
                            expression: "value.agilysis.profitCentreID",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedPos === "omnivore" &&
                _vm.selectedOmnivoreType !== null
                  ? _c(
                      "field-row",
                      {
                        attrs: {
                          title: `Omnivore ${
                            _vm.omnivoreItems.find(
                              (x) => x.value === _vm.selectedOmnivoreType
                            ).text
                          }`,
                        },
                      },
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: "Omnivore location ID",
                            dense: "",
                            clearable: "",
                            required: "",
                            rules: [
                              (v) => !!v || "Omnivore location ID is required!",
                            ],
                          },
                          model: {
                            value: _vm.value.omnivore.locationID,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.omnivore, "locationID", $$v)
                            },
                            expression: "value.omnivore.locationID",
                          },
                        }),
                        _vm.selectedOmnivoreType === "micros3700"
                          ? [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Menu Price Level",
                                  dense: "",
                                  clearable: "",
                                  required: "",
                                  rules: [
                                    (v) =>
                                      !!v || "Menu Price Level is required!",
                                  ],
                                },
                                model: {
                                  value:
                                    _vm.value.omnivore.micros3700
                                      .menuPriceLevel,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value.omnivore.micros3700,
                                      "menuPriceLevel",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "value.omnivore.micros3700.menuPriceLevel",
                                },
                              }),
                            ]
                          : _vm._e(),
                        _vm.selectedOmnivoreType === "ncrAloha"
                          ? [
                              _c("v-checkbox", {
                                attrs: { label: "Forced modifiers", dense: "" },
                                model: {
                                  value:
                                    _vm.value.omnivore.ncrAloha.forcedModifiers,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value.omnivore.ncrAloha,
                                      "forcedModifiers",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "value.omnivore.ncrAloha.forcedModifiers",
                                },
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }