<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <div class="font-weight-bold header">POS information</div>
            <template v-slot:actions>
                <v-icon left class="icon">
                    mdi-chevron-down
                </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pl-8">
            <field-row
                title="Integrated"
                tooltip="Integrated – Your payment terminal speaks to your point of sale
Non-integrated – Your payment terminal does not speak to your point of sale"
            >
                <v-radio-group
                    v-model="value.isIntegrated"
                    row
                    dense
                >
                    <v-radio value="integrated" label="Integrated" />
                    <v-radio value="nonIntegrated" label="Non-integrated" />
                </v-radio-group>
            </field-row>
            <div v-if="value.isIntegrated === 'nonIntegrated'" class="font-weight-bold pt-4 text-center">
                Thank you! Please reach out to support@getgrab for assistance with creating a Grab tender.
            </div>
            <template v-else-if="value.isIntegrated === 'integrated'">
                <field-row
                    title="POS"
                >
                    <v-row>
                        <v-col>
                            <v-select
                                label="POS"
                                v-model="selectedPos"
                                :items="posItems"
                                attach
                                dense
                                required
                                :rules="[v => !!v || 'POS type is required!']"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                                v-if="selectedPos === 'omnivore'"
                                label="Omnivore type"
                                :items="omnivoreItems"
                                v-model="selectedOmnivoreType"
                                dense
                                attach
                                required
                                :rules="[v => !!v || 'Omnivore type is required!']"
                            />
                        </v-col>
                    </v-row>
                </field-row>
                <field-row
                    v-if="selectedPos === 'hmshost'"
                    title="HMSHost Middleware"
                >
                    <v-text-field
                        v-model="value.hmshost.subscriptionKey"
                        label="SubscriptionKey"
                        clearable
                        dense
                        required
                        :rules="[v => !!v || 'SubscriptionKey is required!']"
                    />
                    <v-text-field
                        v-model="value.hmshost.clientID"
                        label="ClientID"
                        clearable
                        dense
                        required
                        :rules="[v => !!v || 'ClientID is required!']"
                    />
                    <v-text-field
                        v-model="value.hmshost.username"
                        label="UserName"
                        clearable
                        dense
                        required
                        :rules="[v => !!v || 'UserName is required!']"
                    />
                    <v-text-field
                        v-model="value.hmshost.passKey"
                        label="PassKey"
                        clearable
                        dense
                        required
                        :rules="[v => !!v || 'PassKey location is required!']"
                    />
                    <v-text-field
                        v-model="value.hmshost.revenueCenterID"
                        label="RevenueCenterID"
                        clearable
                        dense
                        required
                        :rules="[v => !!v || 'RevenueCenterID location is required!']"
                    />
                    <v-text-field
                        v-model="value.hmshost.propertyID"
                        label="PropertyID"
                        clearable
                        dense
                        required
                        :rules="[v => !!v || 'PropertyID location is required!']"
                    />
                    <v-text-field
                        v-model="value.hmshost.baseURL"
                        label="BaseURL"
                        clearable
                        dense
                        required
                        :rules="[v => !!v || 'BaseURL location is required!']"
                    />
                    <v-text-field
                        v-model="value.hmshost.offlineURL"
                        label="OfflineURL"
                        clearable
                        dense
                        required
                        :rules="[v => !!v || 'OfflineURL location is required!']"
                    />
                    <v-text-field
                        v-model="value.hmshost.tenderType"
                        label="TenderType"
                        clearable
                        dense
                        required
                        :rules="[v => !!v || 'TenderType location is required!']"
                    />
                    <v-text-field
                        v-model="value.hmshost.orderTypeIDRef"
                        label="OrderTypeIDRef"
                        clearable
                        dense
                        required
                        :rules="[v => !!v || 'OrderTypeIDRef location is required!']"
                    />
                </field-row>
                <field-row
                    v-if="selectedPos === 'agilysis'"
                    title="Agilysis"
                >
                    <v-text-field
                        label="Authentication code"
                        v-model="value.agilysis.authCode"
                        dense
                        clearable
                        required
                        :rules="[v => !!v || 'Authentication code is required!']"
                    />
                    <v-text-field
                        label="Client ID"
                        v-model="value.agilysis.clientID"
                        dense
                        clearable
                        required
                        :rules="[v => !!v || 'Client ID is required!']"
                    />
                    <v-text-field
                        label="Soap URL"
                        v-model="value.agilysis.soapURL"
                        dense
                        clearable
                        required
                        :rules="[v => !!v || 'Soap URL is required!']"
                    />
                    <v-text-field
                        label="Tender ID"
                        v-model="value.agilysis.tenderID"
                        dense
                        clearable
                        required
                        :rules="[v => !!v || 'Tender ID is required!']"
                    />
                    <v-text-field
                        label="Check Type ID"
                        v-model="value.agilysis.checkTypeID"
                        dense
                        clearable
                        required
                        :rules="[v => !!v || 'Check Type ID is required!']"
                    />
                    <v-text-field
                        label="Employee ID"
                        v-model="value.agilysis.employeeID"
                        dense
                        clearable
                        required
                        :rules="[v => !!v || 'Employee ID is required!']"
                    />
                    <v-text-field
                        label="Price Level"
                        v-model="value.agilysis.priceLevel"
                        dense
                        clearable
                        required
                        :rules="[v => !!v || 'Price Level is required!']"
                    />
                    <v-text-field
                        label="Profit Centre ID"
                        v-model="value.agilysis.profitCentreID"
                        dense
                        clearable
                        required
                        :rules="[v => !!v || 'Profit Centre ID is required!']"
                    />
                </field-row>
                <field-row
                    v-if="selectedPos === 'omnivore' && selectedOmnivoreType !== null"
                    :title="`Omnivore ${omnivoreItems.find(x => x.value === selectedOmnivoreType).text}`"
                >
                    <v-text-field
                        label="Omnivore location ID"
                        v-model="value.omnivore.locationID"
                        dense
                        clearable
                        required
                        :rules="[v => !!v || 'Omnivore location ID is required!']"
                    />
                    <template v-if="selectedOmnivoreType === 'micros3700'">
                        <v-text-field
                            label="Menu Price Level"
                            v-model="value.omnivore.micros3700.menuPriceLevel"
                            dense
                            clearable
                            required
                            :rules="[v => !!v || 'Menu Price Level is required!']"
                        />
                    </template>
                    <template v-if="selectedOmnivoreType === 'ncrAloha'">
                        <v-checkbox
                            label="Forced modifiers"
                            v-model="value.omnivore.ncrAloha.forcedModifiers"
                            dense
                        />
                    </template>
                </field-row>
            </template>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import FieldRow from "components/create_store/fields/FieldRow";

export default {
    name: "PosSection",
    props: {
        value: {
            type: Object,
            required: true,
        }
    },
    components: {
        FieldRow,
    },
    data() {
        return {
            posItems: [
                {
                    text: 'Omnivore',
                    value: 'omnivore',
                },
                {
                    text: 'HMSHost',
                    value: 'hmshost',
                },
                {
                    text: 'Agilysis',
                    value: 'agilysis',
                },
            ],
            selectedPos: null,
            omnivoreItems: [
                {text: 'Micros', value: 'micros'},
                {text: 'Simphony 1', value: 'simphony1'},
                {text: 'Simphony 2', value: 'simphony2'},
                {text: 'Micros 3700', value: 'micros3700'},
                {text: 'NCR Aloha', value: 'ncrAloha'},
                {text: 'Brink', value: 'brink'},
            ],
            selectedOmnivoreType: null,
        };
    },
}
</script>

<style scoped lang="scss">
@import "../section";
</style>
